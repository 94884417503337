<template>
  <div class="accessories">
    <button class="button field is-info is-light" @click="isCardModalActive = true">
      <b-icon icon="plus"></b-icon>
      <span>Novo acessório</span>
    </button>
    <b-table
      :data="allAccessory"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >{{ props.row.name }}</b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="onEdit(props.row); isCardModalActive = true"
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-danger" @click.prevent="onDelete(props.row)">
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/accessories/store/service'
import Accessory from '../models/accessory'

export default {
  name: 'Accessories',
  data() {
    return {
      model: Accessory.model,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('accessories', ['allAccessory']),
  },
  methods: {
    ...mapActions('accessories', ['getAllAccessories']),
    async init() {
      await this.getAllAccessories()
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async save() {
      const accessory = {
        ...this.model,
      }

      try {
        const save = accessory.id
          ? await service.updateaccessory(accessory)
          : await service.saveaccessory(accessory)

        await this.$success('Acessório')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o acessório?')

        if (result.isConfirmed) {
          await service.deleteaccessory(id)

          await this.$delete('Acessório')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
